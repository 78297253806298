import logo from './logo.svg';
import './App.css';
import React, {useEffect, useState} from "react";
import { config } from './Constants'
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import Loader from 'react-loader-spinner';
import {Alert, Button} from "@mui/material";
const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();
    return (
        promiseInProgress &&
        <div
            style={{
                width: "100%",
                height: "100",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" />
        </div>
    );
}

function App() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let foo = params.get('code');
    let myDate = params.get('myDate');
    let myState = parseInt(params.get('myState'));
    const [result, setResult] = useState({});
    let day = myDate || new Date().getUTCDate();
    let delay = day >=27 || day <= 3;

    useEffect(()=>{
        if (!foo) {
            setResult({"error":"Code not generated"});
            return;
        }
        trackPromise(
        fetch(config.url.G_SCRIPT+foo)
            .then(res => res.json())
            .then(json => {
                setResult(json);
            }));
    }, [foo]);

    return (
    <div className="App">
        {delay &&
        <Alert severity="error">
            Expect processing delays for subscriptions near the end/start of month
        </Alert>
        }
        <header className="App-header">
            <div>
                <Button href={config.url.PAT_REDIR} variant="contained" color="success">Generate auth code</Button>
            </div>
                <LoadingIndicator />
                {codes(result, day, myState)}
            <br/>
            <br/>
            <Button href={"https://www.oldschoolscripts.com/"} variant="contained">Help/Documentation</Button>
            <br/>
            <Button href={"https://www.youtube.com/channel/UCS8KcVf_CnNPPLpvbj1RyXA/"} color="error" variant="contained">Youtube</Button>
        </header>
    </div>
  );
}

function codes(result, day, myState) {
    let special = day >= 20 || (day >= 5 && day <= 8);

    if (result['error']) {
        let message = result['error'];
        if (message.includes('Please') || myState === 1) {
            return (
                <>
                    <h1>
                        <div>Resubscribe to gain access</div>
                        <br/>
                        {special && <div>Limited time special! $5 to generate code</div>}
                        <Button
                            style={{
                                borderRadius: 35,
                                backgroundColor: "#f96854",
                                padding: "18px 36px",
                                fontSize: "18px"
                            }}
                            variant="contained"
                            href={special ? "https://www.patreon.com/posts/33697324" : "https://www.patreon.com/posts/39017080"}
                        >
                            {special ? "Join $5+" : "Join $10+"}
                        </Button>
                    </h1>
                </>
            )
        }
        if (message.includes('No') || myState === 2) {
            return (
                <>
                    <h1>
                        Try again
                    </h1>
                </>
            )
        }
        return (<></>)
    } else if (result['code']) {
        return (
        <>
            <div className="showCode">
                {result['code']}
            </div>
            <button
                onClick={() =>  navigator.clipboard.writeText(result['code'])}
            >
                Copy to clipboard
            </button>
        </>
        )
    }
}

export default App;
