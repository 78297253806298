// Constants.js
const prod = {
    url: {
        PAT_REDIR: 'https://www.patreon.com/oauth2/authorize?response_type=code&client_id=TPdaw0rTCnqwMPLyBZnQbDqBc3pHA_o1Fru-7RSjP5CrAebNqqPcVnXV0UF9F6FC&redirect_uri=https%3A%2F%2Fnom-auth.pages.dev%2F&scope=identity%20identity%5Bemail%5D',
        G_SCRIPT: 'https://script.google.com/macros/s/AKfycbyBFaRwfI4y3a7GDFszQCrJ-NK-cZWIJq8a_44ySAO5EbImDz7O4O9OrYovp6Sy5sdd/exec?'
    }
};

const dev = {
    url: {
        PAT_REDIR: 'https://www.patreon.com/oauth2/authorize?response_type=code&client_id=TPdaw0rTCnqwMPLyBZnQbDqBc3pHA_o1Fru-7RSjP5CrAebNqqPcVnXV0UF9F6FC&redirect_uri=http%3A%2F%2Flocalhost%3A3000&scope=identity%20identity%5Bemail%5D',
        G_SCRIPT: 'https://script.google.com/macros/s/AKfycbyoFYqlDXI9MyOemFqCv7RqXFDMfY6t99to9-gOlWBTiLO_TBztMLrML3kr1ofkCJ-c/exec?'
    }
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;